/* global Component */
class OppenErrorComponent extends  Component {
    static name() {
        return "oppenErrorDisplay";
    }

    static componentName() {
        return "oppenErrorDisplay";
    }

    getProps() {
        return  {
            'errorData': {
                type: Object,
                require: true
            },
            updateCallback: {
                type: Function,
                require: false
            }
        };
    }

    getComputed(){ 
        return {
            messageText: function (){
                window.$oppenError = this;
                return this.getDisplayMessage(this.errorData);
            },
        };
    }

    getMethods() {
        return {
            getDisplayMessage: this.getDisplayMessage,
        };
    }

    getDisplayMessage(err) {
        let label = '';
        let srvmessage = '';
        let trparams;
        let fieldMessage = "";
        let premessage = '';
        let errorType;
        if(this.errorData.hasOwnProperty('Type'))
            errorType = this.errorData.Type;
        else {
            if(this.errorData.params && this.errorData.params.hasOwnProperty('Type'))
                errorType = this.errorData.params.Type;
            else
                errorType = 'Plaintext';
        }

        switch (errorType) {
            case 'FieldErrorResponse':
                label = this.tr(err.errorParams.FieldName);
                if (err.code == 'LINKTOINVALIDVALUEERR') {
                    let message = this.tr(err.code, label);
                    message = message.replace('%linkValue%', err.errorParams.FieldValue);
                    message = message.replace('%linkToField%', this.tr(label));
                    message = message.replace('%linkRegister%', this.tr(err.originator.name));
                    message = message.replace('%linkRecordId%', err.originator.id);
                    return srvmessage + message;
                }
                if (!(this.tr(err.code.includes("{{")))) {
                    fieldMessage = ", " + this.tr('Field') + " " + this.tr(label) + " " + this.tr((err.errorParams.FieldValue ? err.errorParams.FieldValue : ""));
                }
                return srvmessage + premessage + this.tr(err.code, label, err.errorParams.FieldValue) + fieldMessage;
            case 'RowFieldErrorResponse': {
                label = this.tr(err.params.RowFieldName);
                switch (err.code) {
                    case 'LINKTOINVALIDVALUEERR': {
                        let message = this.tr(err.code, label);
                        message = message.replace('%linkValue%', err.params.FieldValue);
                        message = message.replace('%linkToField%', this.tr(label));
                        message = message.replace('%linkRegister%', this.tr(err.originator.name));
                        message = message.replace('%linkRecordId%', err.originator.id);
                        return srvmessage + message;
                    }
                    case 'ECOMITEMEXCLUDE': {
                        const storeItem = this.$store.getters.getItemByCode(err.params.RowFieldName);
                        if(storeItem){
                            this.$store.dispatch('changeCartQty',{itemCode:storeItem.Code,action:'delete'}).then(()=>{
                                this.$store.dispatch("loadInitItems");
                                this.$store.dispatch('showNotificacion',{title:this.tr('Information', storeItem.Name), message: this.tr(['ECOMITEMCARREMOVED', storeItem.Name]),type: 'info'});
                            });
                        }
                        return this.tr([err.code, storeItem ? storeItem.Name : err.params.RowFieldName]);
                    }
                    default: {
                        let detail = this.tr(err.params.FieldName) + ": " + this.tr('Row') + " " + (err.params.RowNr + 1) + ", " + this.tr('Field') + " " + this.tr(label);
                        return this.tr(err.code, label, err.params.FieldValue) + ". " + detail;
                    }
                }
            }
            case 'Plaintext' : {
                return this.tr("Reasons") + ":\n" + this.tr(err);
            }
            default: {
                premessage = this.tr("We were unable to create your order, please try again or contact your account manager to be assisted");
                if (err.params) {
                    trparams = [err.code];
                    trparams = Array.concat(trparams,Object.values(err.params));
                    return premessage + this.tr("Reasons") + ":\n" + this.tr.apply(trparams);
                }
                return premessage;
            }
        }
    }

    getTemplate() {
        return ` 
            <div class="col-12 col-md-6 offset-md-3 error-message px-1">
                <p class="text-center">{{messageText}}</p>
            </div>`;
    }
}

OppenErrorComponent.registerComponent();